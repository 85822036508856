.liked-users-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    backdrop-filter: blur(5px);

    .modal-content {
        background: #FFFFFF;
        border-radius: 15px;
        width: 90%;
        max-width: 400px;
        max-height: 70vh;
        box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
        position: relative;
        padding: 40px 20px 20px;

        h3 {
            font-size: 18px;
            color: #262626;
            margin: 0 0 20px;
            padding-bottom: 15px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.08);
            display: flex;
            align-items: center;
            gap: 8px;

            &::before {
                content: '❤️';
                font-size: 20px;
            }
        }

        .liked-users-list {
            list-style: none;
            padding: 0;
            margin: 0;
            overflow-y: auto;
            max-height: 50vh;

            /* Custom Scrollbar */
            &::-webkit-scrollbar {
                width: 6px;
            }
            
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
                border-radius: 3px;
            }
            
            &::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 3px;
            }

            .liked-user {
                padding: 12px 0;
                
                &:not(:last-child) {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                }

                a {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    gap: 12px;
                    transition: background 0.2s;
                    padding: 8px;
                    border-radius: 8px;

                    &:hover {
                        background: rgba(0, 0, 0, 0.03);
                    }

                    .user-avatar {
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        object-fit: cover;
                        border: 1px solid rgba(0, 0, 0, 0.08);
                    }

                    .user-login {
                        font-size: 14px;
                        font-weight: 600;
                        color: #262626;
                    }
                }
            }
        }

        > p {
            color: #8e8e8e;
            font-size: 14px;
            padding: 16px 0;
        }

        .close-button {
            position: absolute;
            top: 15px;
            right: 15px;
            background: rgba(0, 0, 0, 0.1);
            border: none;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            color: #666;
            font-size: 18px;
            cursor: pointer;
            transition: all 0.2s ease;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background: rgba(0, 0, 0, 0.15);
                color: #000;
            }
        }
    }
}

/* Mobile Adaptation */
@media (max-width: 480px) {
    .liked-users-modal .modal-content {
        width: 100%;
        height: 80vh;
        border-radius: 15px 15px 0 0;
        
        h3 {
            font-size: 16px;
        }
        
        .liked-user a .user-avatar {
            width: 32px;
            height: 32px;
        }
    }
}

/* Loading Animation */
@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}

.loading-state {
    animation: pulse 1.5s infinite;
    text-align: center;
    padding: 20px 0;
    
    p {
        color: #8e8e8e;
        font-size: 14px;
    }
}