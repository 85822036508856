.my-stories {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 10000;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;

  // Шапка
  .stories-header {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1000;
    background: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, transparent 100%);

    .user-info {
      display: flex;
      align-items: center;
      gap: 12px;

      .avatar-container {
        .user-avatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 2px solid #fff;
          box-shadow: 0 2px 8px rgba(0,0,0,0.2);
        }
      }

      .user-details {
        .username {
          color: #fff;
          font-weight: 600;
          font-size: 16px;
        }

        .timestamp {
          color: rgba(255,255,255,0.9);
          font-size: 13px;
          margin-top: 2px;
        }
      }
    }

    .close-btn {
      background: rgba(255,255,255,0.1);
      border: none;
      border-radius: 50%;
      padding: 8px;
      backdrop-filter: blur(4px);
      svg {
        width: 24px;
        height: 24px;
        fill: #fff;
      }
    }
  }

  // Прогресс-бар
  .progress-container {
    padding: 12px 16px;
    display: flex;
    gap: 4px;
    position: relative;
    z-index: 1000;

    .progress-track {
      flex: 1;
      height: 2px;
      background: rgba(255,255,255,0.3);
      border-radius: 2px;
      overflow: hidden;

      .progress-bar {
        height: 100%;
        background: #fff;
        transition: width 0.1s linear;
        box-shadow: 0 0 8px rgba(255,255,255,0.3);
      }
    }
  }

  // Контент
  .content-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    .story-content {
      width: 100%;
      height: 100%;
      
      img, video {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  // Слой управления
  .controls-layer {
    position: relative;
    z-index: 1000;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    pointer-events: none;

    > * {
      pointer-events: auto;
    }
  }

  // Превью просмотров
  .viewers-preview {
    align-self: flex-start;
    margin: 16px;
    padding: 8px 12px;
    background: rgba(255,255,255,0.1);
    border-radius: 20px;
    backdrop-filter: blur(8px);
    display: flex;
    align-items: center;
    gap: 8px;

    .preview-avatars {
      display: flex;
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 2px solid #fff;
        margin-left: -8px;
        &:first-child { margin-left: 0; }
      }
    }

    .preview-count {
      color: #fff;
      font-weight: 600;
      font-size: 14px;
    }
  }

  // Футер
  .story-footer {
    position: fixed;
    bottom: 20px;
    left: 16px;
    right: 16px;
    z-index: 1001;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, transparent 100%);
    padding: 16px;

    .story-text {
      color: #fff;
      font-size: 16px;
      line-height: 1.4;
      text-shadow: 0 2px 8px rgba(0,0,0,0.5);
      max-width: 80%;
      z-index: 100;
    }

    .more-btn {
      background: rgba(255,255,255,0.1);
      border: none;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      padding: 8px;
      backdrop-filter: blur(4px);
      
      svg {
        fill: #fff;
      }
    }
  }

  // Модальные окна
  .viewers-modal {
    position: fixed;
    top: 15%;
    left: 50%;
    z-index: 10010;
    width: 90%;
    max-width: 400px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 8px 32px rgba(0,0,0,0.2);

    &::before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,0.5);
      z-index: -1;
    }

    .modal-content {
      padding: 16px;
      
      h3 {
        margin: 0 0 16px;
        font-size: 18px;
      }

      .viewers-list {
        max-height: 50vh;
        overflow-y: auto;
        
        li {
          padding: 12px 0;
          border-bottom: 1px solid #eee;
        }
      }

      .close-modal {
        width: 100%;
        margin-top: 16px;
        padding: 12px;
        background: #f5f5f5;
        color: #333;
      }
    }
  }

  .delete-modal, .viewers-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10001;

    .modal-content {
      background: #fff;
      border-radius: 16px;
      padding: 24px;
      width: 90%;
      max-width: 400px;

      h3 {
        margin: 0 0 20px;
        font-size: 20px;
        color: #333;
      }

      .modal-actions {
        display: flex;
        gap: 12px;
        justify-content: center;

        button {
          padding: 12px 24px;
          border: none;
          border-radius: 8px;
          font-weight: 600;
          cursor: pointer;

          &.confirm-btn {
            background: #ff4444;
            color: #fff;
          }

          &.cancel-btn {
            background: #eee;
            color: #333;
          }
        }
      }

      .viewers-list {
        list-style: none;
        padding: 0;
        margin: 0 0 20px;

        li {
          padding: 12px 0;
          border-bottom: 1px solid #eee;

          &:last-child {
            border-bottom: none;
          }
        }

        .viewer-item {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #333;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 12px;
          }
        }
      }

      .close-modal {
        width: 100%;
        padding: 12px;
        background: #007bff;
        color: #fff;
        border: none;
        border-radius: 8px;
        cursor: pointer;
      }
    }
  }
}