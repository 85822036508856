.comment-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1300;
    backdrop-filter: blur(5px);

    &-content {
        background: #FFFFFF;
        border-radius: 15px;
        width: 90%;
        max-width: 500px;
        max-height: 80vh;
        box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
        position: relative;
        padding: 20px 15px;
    }
}

.close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: rgba(0, 0, 0, 0.1);
    border: none;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: #666;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
        background: rgba(0, 0, 0, 0.15);
    }
}

.comments-section {
    padding: 10px 0;
}

.comments-list {
    max-height: 60vh;
    overflow-y: auto;
    padding-right: 8px;

    /* Custom Scrollbar */
    &::-webkit-scrollbar {
        width: 6px;
    }
    
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 3px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 3px;
    }
}

.comment {
    display: flex;
    gap: 12px;
    padding: 12px 0;
    position: relative;
    
    &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }

    .commentMain {
        flex-grow: 1;
        display: flex;
        gap: 12px;
        align-items: flex-start;

        img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            object-fit: cover;
            flex-shrink: 0;
        }

        .commentContent {
            flex-grow: 1;
            
            strong {
                font-size: 14px;
                font-weight: 600;
                color: #262626;
                margin-right: 8px;
            }

            nav {
                font-size: 14px;
                color: #333;
                line-height: 1.4;
                margin-top: 2px;
            }

            .comment-actions {
                margin-top: 6px;
                display: flex;
                align-items: center;
                gap: 16px;

                button {
                    background: none;
                    border: none;
                    padding: 0;
                    font-size: 12px;
                    color: #8e8e8e;
                    cursor: pointer;
                    
                    &:hover {
                        color: #262626;
                    }
                }

                .time-ago {
                    font-size: 12px;
                    color: #8e8e8e;
                }
            }
        }
    }

    .deleteComment {
        position: relative;
        
        img {
            width: 20px;
            opacity: 0.6;
            transition: opacity 0.2s;
            padding: 4px;
            
            &:hover {
                opacity: 1;
            }
        }

        .confirmation {
            position: absolute;
            right: 0;
            top: 24px;
            background: white;
            border-radius: 8px;
            box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
            padding: 8px 12px;
            
            .deleteButton {
                color: #ED4956;
                font-weight: 600;
                font-size: 14px;
                white-space: nowrap;
                
                &:hover {
                    opacity: 0.9;
                }
            }
        }
    }
}

.add-comment {
    position: relative;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);

    input {
        width: 100%;
        padding: 12px 48px 12px 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 24px;
        font-size: 14px;
        background: #fafafa;
        transition: all 0.2s ease;
        
        &:focus {
            background: white;
            border-color: #0095f6;
            outline: none;
        }
    }

    .send-button {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: none;
        padding: 6px;
        cursor: pointer;
        
        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        .send-icon {
            font-size: 20px;
            color: #0095f6;
            transition: transform 0.2s ease;
            
            &:hover {
                transform: translateX(2px);
            }
        }
    }
}

/* Mobile Adaptation */
@media (max-width: 480px) {
    .comment-modal-content {
        width: 100%;
        height: 90vh;
        border-radius: 15px 15px 0 0;
    }
    
    .comment .commentMain img {
        width: 32px;
        height: 32px;
    }
}