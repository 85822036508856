/* postPage.scss */
.postPage {
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    margin: 20px auto;
    max-width: 600px;
    position: relative;
  
    .postPage-header {
      padding: 16px;
      display: flex;
      align-items: center;
      gap: 12px;

      .postHeader-login{
        display: flex;
        align-items: center;
        gap: 10px;
      }
  
      .postHeader-loginContainer {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #efefef;
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
  
      span {
        font-weight: 600;
        font-size: 14px;
        color: #262626;
      }
    }
  
    .postPage-main {
      position: relative;
      background: #000;
  
      .double-tap-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        z-index: 2;
      }
  
      img, video {
        width: 100%;
        max-height: 800px;
        object-fit: contain;
        display: block;
      }
  
      .heart-animation {
        animation: heartBurst 0.8s ease-out forwards;
        font-size: 80px;
        color: rgba(255, 255, 255, 0.9);
        opacity: 0;
        position: relative;
        z-index: 3;
      }
  
      @keyframes heartBurst {
        0% { transform: scale(0); opacity: 1; }
        50% { transform: scale(1.2); opacity: 1; }
        100% { transform: scale(1); opacity: 0; }
      }
    }
  
    .postPage-footer {
      padding: 12px 16px;
  
      .pageFooter-countsInf {
        display: flex;
        gap: 16px;
        margin-bottom: 8px;
  
        nav {
          display: flex;
          align-items: center;
          gap: 6px;
          cursor: pointer;
  
          img {
            width: 24px;
            height: 24px;
            transition: transform 0.2s;
  
            &:active {
              transform: scale(0.9);
            }
          }
  
          span {
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
  
      .pageFooter-underText {
        .pageFooter-paragraph {
          font-size: 14px;
          margin: 6px 0;
          cursor: pointer;
          transition: color 0.2s;
  
          &.expanded {
            white-space: normal !important;
            overflow: visible !important;
          }
  
          &:hover {
            color: #333;
          }
        }
  
        .liked-users {
          font-size: 14px;
          color: #262626;
          margin: 8px 0;
  
          a {
            font-weight: 600;
            color: inherit;
            text-decoration: none;
  
            &:hover {
              text-decoration: underline;
            }
          }
  
          span {
            color: #8e8e8e;
            cursor: pointer;
          }
        }
  
        .postDate {
          color: #8e8e8e;
          font-size: 10px;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          margin-top: 6px;
        }
      }
    }
  }
  
  @media (max-width: 650px) {
    .postPage {
      border-radius: 0;
      border-left: none;
      border-right: none;
      margin: 0;
  
      .postPage-main {
        img, video {
          max-height: 100vh;
        }
      }
    }
  }
  
  .custom-video {
    position: relative;
    
    .video-controls {
      position: absolute;
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
      background: rgba(0, 0, 0, 0.6);
      border-radius: 20px;
      padding: 8px;
      display: flex;
      gap: 12px;
      opacity: 0;
      transition: opacity 0.3s;
  
      &.visible {
        opacity: 1;
      }
  
      button {
        background: none;
        border: none;
        color: white;
        font-size: 24px;
        cursor: pointer;
        padding: 0;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  
  .loading, .no-posts {
    text-align: center;
    padding: 40px;
    font-size: 18px;
    color: #8e8e8e;
  }