.Hosting{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media(max-width: 768px){
    .Hosting{
        position: relative;
        bottom: 40px;
    }
}