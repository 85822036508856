// Цвета
$primary: #3897f0;
$error: #ed4956;
$background: #ffffff;
$border: #dbdbdb;
$text-primary: #262626;
$text-secondary: #8e8e8e;

// Тени
$shadow: 0 8px 24px rgba(0,0,0,0.1);
$inner-shadow: inset 0 1px 2px rgba(0,0,0,0.05);

// Анимации
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.story-upload {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 420px;
  background: $background;
  border-radius: 24px;
  box-shadow: $shadow;
  z-index: 10010;
  animation: fadeIn 0.3s ease;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-bottom: 1px solid $border;

    h2 {
      font-size: 1.25rem;
      margin: 0;
      color: $text-primary;
      font-weight: 600;
    }

    .close-btn {
      background: none;
      border: none;
      color: $text-primary;
      font-size: 1.2rem;
      cursor: pointer;
      padding: 8px;
      border-radius: 50%;
      transition: background 0.2s;

      &:hover {
        background: rgba(0,0,0,0.05);
      }
    }
  }

  .error-message {
    background: lighten($error, 36%);
    color: $error;
    padding: 12px 24px;
    margin: 0 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.9rem;

    span {
      font-weight: bold;
      font-size: 1.1rem;
    }
  }

  .upload-section {
    padding: 24px;

    .upload-controls {
      display: flex;
      gap: 24px;
      justify-content: center;
      margin-bottom: 24px;

      .file-input-label,
      .camera-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        cursor: pointer;
        background: none;
        border: none;
        padding: 0;

        .icon-wrapper {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          background: rgba($primary, 0.1);
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.2s;

          .icon {
            color: $primary;
            font-size: 24px;
          }
        }

        span {
          color: $text-primary;
          font-size: 0.875rem;
          font-weight: 500;
        }

        &:hover .icon-wrapper {
          transform: scale(1.05);
          background: rgba($primary, 0.15);
        }
      }

      .vertical-divider {
        width: 1px;
        background: $border;
        margin: 16px 0;
      }
    }
  }

  .preview-container {
    position: relative;
    margin: 16px;
    border-radius: 16px;
    overflow: hidden;
    border: 1px solid $border;

    .preview {
      width: 100%;
      max-height: 600px;
      object-fit: cover;
      display: block;
      border-radius: 16px;
    }
  }

  .text-input {
    position: relative;
    margin: 0 24px 24px;

    textarea {
      width: 100%;
      height: 100px;
      padding: 16px;
      border: 1px solid $border;
      border-radius: 12px;
      resize: none;
      font-size: 0.875rem;
      background: $background;
      transition: all 0.2s;

      &:focus {
        outline: none;
        border-color: $primary;
        box-shadow: 0 0 0 2px rgba($primary, 0.2);
      }

      &:placeholder-shown + label {
        transform: translate(16px, 16px);
      }

      &:focus + label,
      &:not(:placeholder-shown) + label {
        transform: translate(8px, -8px);
        font-size: 0.75rem;
        color: $primary;
        background: $background;
        padding: 0 4px;
      }
    }

    label {
      position: absolute;
      left: 12px;
      top: 12px;
      color: $text-secondary;
      font-size: 0.875rem;
      pointer-events: none;
      transition: all 0.2s;
    }
  }

  .upload-button {
    background: $primary;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 14px 24px;
    font-size: 0.875rem;
    font-weight: 600;
    width: calc(100% - 48px);
    margin: 0 24px 24px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background: darken($primary, 5%);
      box-shadow: $shadow;
    }

    &:active {
      transform: scale(0.98);
    }

    span {
      display: block;
    }
  }

  .progress-container {
    height: 2px;
    background: rgba($primary, 0.1);
    margin: 0 24px 24px;
    border-radius: 2px;
    overflow: hidden;

    .progress-bar {
      height: 100%;
      background: $primary;
      transition: width 0.3s ease;
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(8px);
  z-index: 1000;
}