/* Основной контейнер */
.insta-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #fafafa;
  padding: 20px;
}

/* Карточка создания поста */
.insta-card {
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 12px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Заголовок */
.insta-header {
  padding: 20px;
  border-bottom: 1px solid #efefef;
  text-align: center;
}
.insta-header h2 {
  margin: 0;
  font-size: 22px;
  color: #262626;
}

/* Секция загрузки файла (блок стал меньше) */
.insta-upload {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.insta-upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  max-width: 300px;
  padding: 15px 20px; /* уменьшено по сравнению с предыдущим вариантом */
  border: 2px dashed #dbdbdb;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;
}
.insta-upload-label:hover {
  background: #f8f8f8;
}
.insta-upload-icon {
  color: #8e8e8e;
  margin-bottom: 8px;
}
.insta-upload-label input {
  display: none;
}
.insta-upload-label span {
  font-size: 14px;
  color: #8e8e8e;
}

/* Сообщение об ошибке */
.insta-error {
  margin-top: 10px;
  color: #ed4956;
  font-size: 14px;
}

/* Секция предпросмотра и описания (блок стал больше) */
.insta-preview-section {
  padding: 20px;
}
.insta-preview {
  width: 100%;
  max-height: 600px; /* увеличено с 400px до 600px */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  border: 1px solid #efefef;
  border-radius: 8px;
  margin-bottom: 15px;
}
.insta-preview img,
.insta-preview video {
  width: 100%;
  object-fit: cover;
}

/* Текстовое поле для описания */
.insta-textarea {
  width: 100%;
  min-height: 80px;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  resize: vertical;
  margin-bottom: 15px;
  outline: none;
  transition: border-color 0.3s;
}
.insta-textarea:focus {
  border-color: #a8a8a8;
}

/* Кнопка публикации */
.insta-button {
  width: 100%;
  background: #3897f0;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 12px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: background 0.3s;
}
.insta-button:hover:not(:disabled) {
  background: #3181d4;
}
.insta-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.insta-button-icon {
  color: #fff;
}

/* Прогресс загрузки */
.insta-progress {
  width: 100%;
  margin-top: 10px;
}
