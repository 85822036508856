.bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: #fff;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 1000;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.05);
  
    .nav-item {
      text-decoration: none;
      color: #555;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: color 0.3s ease;
  
      &:hover {
        color: #000;
      }
  
      &.active {
        color: #000;
      }
  
      svg {
        margin-bottom: 4px;
      }
    }
  }

  
  @media (min-width: 768px) {
    .bottom-nav {
      display: none;
    }
  }