.user-recommendations {
    padding: 16px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: 16px auto;
    max-width: 100%;
  
    h2 {
      font-size: 15px;
      margin-bottom: 12px;
      color: #333;
      text-align: start;
    }
  
    .recommendations-loading {
      text-align: center;
      font-size: 16px;
      color: #666;
    }
  
    .recommendations-list {
      display: flex;
      overflow-x: auto;
      gap: 12px;
      padding: 0;
      margin: 0;
      list-style: none;
  
      // Скрываем скроллбар для Webkit, Firefox и IE/Edge:
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
  
      .recommendation-item {
        background-color: #f8f8f8;
        border-radius: 10px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
        padding: 12px;
        min-width: 200px;  // фиксированная минимальная ширина карточки
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
  
        .user-link {
          text-decoration: none;
          color: inherit;
          display: flex;
          flex-direction: column;
          align-items: center;
  
          .user-avatar {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            object-fit: cover;
            border: 2px solid #ddd;
            margin-bottom: 8px;
          }
  
          .user-login {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 8px;
          }
        }
  
        .follow-button {
          background: #3897f0;
          color: #fff;
          border: none;
          border-radius: 4px;
          padding: 6px 12px;
          cursor: pointer;
          font-size: 14px;
          margin-bottom: 8px;
          transition: background 0.3s;
        }
  
        .follow-button:hover:not(:disabled) {
          background: #2a85d0;
        }
  
        .follow-button:disabled {
          background: #aaa;
          cursor: not-allowed;
        }
  
        .followers-info {
          font-size: 12px;
          color: #666;
          margin-top: 8px;
  
          span {
            display: block;
            margin-bottom: 4px;
          }
  
          .followers-avatars {
            display: flex;
            justify-content: center;
            gap: 4px;
            margin-top: 4px;
  
            img {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              object-fit: cover;
              border: 1px solid #ddd;
            }
          }
        }
      }
    }
  }
  