.story-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 10000;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  touch-action: none;

  // Шапка
  .story-header {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1000;
    background: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, transparent 100%);

    .user-info {
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;

      .avatar-container {
        .avatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 2px solid rgba(255,255,255,0.3);
          box-shadow: 0 2px 8px rgba(0,0,0,0.2);
        }
      }

      .user-details {
        .login {
          color: #fff;
          font-weight: 600;
          font-size: 16px;
        }

        .time-ago {
          color: rgba(255,255,255,0.9);
          font-size: 13px;
          margin-top: 2px;
        }
      }
    }

    .close-btn {
      background: rgba(255,255,255,0.1);
      border: none;
      border-radius: 50%;
      padding: 8px;
      backdrop-filter: blur(4px);
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  // Прогресс-бар
  .progress-container {
    padding: 12px 16px;
    display: flex;
    gap: 4px;
    position: relative;
    z-index: 1000;

    .progress-track {
      flex: 1;
      height: 2px;
      background: rgba(255,255,255,0.3);
      border-radius: 2px;
      overflow: hidden;

      .progress-bar {
        height: 100%;
        background: #fff;
        transition: width 0.1s linear;
        box-shadow: 0 0 8px rgba(255,255,255,0.3);
      }
    }
  }

  // Контент
  .content-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    .nav-area {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 25%;
      cursor: pointer;
      z-index: 999;

      &.prev { left: 0; }
      &.next { right: 0; }
    }

    .story-content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .story-media {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        transition: opacity 0.3s;
      }
    }
  }

  // Текст
  .story-text {
    position: fixed;
    bottom: 20px;
    left: 16px;
    right: 16px;
    z-index: 1001;
    color: #fff;
    font-size: 16px;
    line-height: 1.4;
    text-shadow: 0 2px 8px rgba(0,0,0,0.5);
    padding: 12px 16px;
    background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, transparent 100%);
    border-radius: 8px;
  }

  @media (max-width: 480px) {
    .story-header {
      padding: 8px 12px;
      
      .user-info .avatar {
        width: 32px;
        height: 32px;
      }
    }
    
    .story-text {
      font-size: 14px;
      bottom: 15px;
    }
  }
}