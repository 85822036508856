// Основные стили для контейнера поста
.user-postPage {
    background: #ffffff;
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    max-width: 600px;
    margin: 20px auto;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  
    // Стили для заголовка поста
    .postPage-header {
      padding: 14px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #efefef;
  
      .postHeader-login {
        display: flex;
        align-items: center;
        gap: 12px;
  
        .postHeader-loginContainer {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          border: 1px solid #e0e0e0;
          flex-shrink: 0;
  
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
          }
        }
  
        span {
          font-size: 14px;
          font-weight: 600;
          color: #262626;
        }
      }
  
      .postHeader-nav {
        position: relative;
  
        img {
          cursor: pointer;
          padding: 6px;
          width: 28px;
          height: 28px;
          transition: background 0.2s;
  
          &:hover {
            background: rgba(0,0,0,0.05);
            border-radius: 50%;
          }
        }
  
        .post-menu {
          position: absolute;
          right: 0;
          top: 100%;
          background: #ffffff;
          border: 1px solid #dbdbdb;
          border-radius: 6px;
          box-shadow: 0 4px 12px rgba(0,0,0,0.1);
          padding: 8px 0;
          width: 200px;
          z-index: 1000;
  
          li {
            padding: 12px 16px;
            font-size: 14px;
            color: #262626;
            cursor: pointer;
            transition: background 0.2s;
  
            &:hover {
              background: #f8f8f8;
            }
  
            &.delete {
              color: #ed4956;
              font-weight: 500;
            }
          }
        }
      }
    }
  
    // Стили для основного контента (изображение/видео)
    .postPage-main {
      position: relative;
      background: #000000;
  
      .double-tap-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        z-index: 2;
  
        .heart-animation {
          animation: heartBurst 0.8s ease-out forwards;
          font-size: 80px;
          color: rgba(255, 255, 255, 0.9);
          opacity: 0;
        }
      }
  
      img, video {
        width: 100%;
        max-height: 600px;
        object-fit: contain;
        display: block;
      }
  
      @keyframes heartBurst {
        0% { transform: scale(0); opacity: 1; }
        50% { transform: scale(1.2); opacity: 1; }
        100% { transform: scale(1); opacity: 0; }
      }
    }
  
    // Стили для футера поста (лайки, комментарии, описание)
    .postPage-footer {
      padding: 12px 16px;
  
      .interaction-buttons {
        padding: 8px 0;
        border-bottom: 1px solid #efefef;
  
        .left-buttons {
          display: flex;
          gap: 16px;
  
          button {
            background: none;
            border: none;
            display: flex;
            align-items: center;
            gap: 6px;
            cursor: pointer;
            padding: 4px;
  
            img {
              width: 24px;
              height: 24px;
              transition: transform 0.15s;
  
              &:active {
                transform: scale(0.9);
              }
            }
  
            span {
              font-size: 14px;
              font-weight: 600;
              color: #262626;
            }
  
            &.active {
              color: #ed4956;
            }
          }
        }
      }
  
      .post-info {
        padding: 8px 0;
  
        .description {
          font-size: 14px;
          margin: 8px 0;
          line-height: 1.4;
          color: #262626;
        }
  
        .post-date {
          display: block;
          font-size: 10px;
          color: #8e8e8e;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          margin-top: 8px;
        }
  
        .liked-users {
          font-size: 14px;
          margin: 12px 0;
          color: #262626;
  
          a {
            font-weight: 600;
            color: inherit;
            text-decoration: none;
  
            &:hover {
              text-decoration: underline;
            }
          }
  
          button {
            background: none;
            border: none;
            color: #8e8e8e;
            cursor: pointer;
            padding: 0;
            font-size: 14px;
  
            &:hover {
              color: #262626;
            }
          }
        }
      }
    }
  }
  
  // Стили для кастомного видео-плеера
  .custom-video {
    position: relative;
    background: #000;
  
    video {
      width: 100%;
      height: auto;
      max-height: 600px;
    }
  
    .video-controls {
      position: absolute;
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
      background: rgba(0,0,0,0.6);
      border-radius: 20px;
      padding: 8px;
      display: flex;
      gap: 12px;
      opacity: 0;
      transition: opacity 0.3s;
  
      &.visible {
        opacity: 1;
      }
  
      button {
        background: none;
        border: none;
        color: white;
        font-size: 24px;
        cursor: pointer;
        padding: 0;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  
  // Адаптация для мобильных устройств
  @media (max-width: 650px) {
    .user-postPage {
      border-radius: 0;
      border-left: none;
      border-right: none;
      margin: 0;
  
      .postPage-main {
        img, video {
          max-height: 70vh;
        }
      }
  
      .postPage-footer {
        padding: 12px;
  
        .post-info {
          .liked-users {
            font-size: 13px;
          }
        }
      }
    }
  }
  
  // Стили для состояний загрузки и ошибок
  .loading, .error {
    text-align: center;
    padding: 40px;
    font-size: 16px;
    color: #8e8e8e;
  }